.wrpr {
  text-align: center;

  & > label > div {
    margin-right: var(--spacing-2);
  }
}

.link {
  color: #fff;
}
