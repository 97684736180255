.loading {
  background-color: var(--color-neutral-100);
  height: 180px;
  width: 100%;
}

.wrapper {
  border: 1px solid var(--color-neutral-100);
  padding: var(--spacing-3);
  overflow: hidden;
  position: relative;

  @media (--mobile) {
    h1 {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: var(--spacing-3);
    }
  }

  @media (--tablet) {
    padding: 0 var(--spacing-5) var(--spacing-6);

    h1 {
      font-size: 28px;
      line-height: 40px;
    }
  }

  & :global(.intro-scrollbox) {
    gap: var(--spacing-2);
    scroll-snap-type: x mandatory;
    align-items: flex-start;

    @media (--mobile) {
      width: calc(100vw - 70px);
    }

    @media (--tablet) {
      width: 100%;
    }
  }

  & :global(.intro-scrollbox-nav) {
    gap: 0;
    display: none;

    & svg {
      height: var(--spacing-11);
      width: var(--spacing-11);
    }

    @media (--tablet-l) {
      display: flex;
      top: auto;
      bottom: -15px;
      right: -10px;
    }
  }

  & :global(.intro-scrollbox-dots) {
    margin-top: 10px;

    @media (--mobile) {
      margin-top: 0;
      justify-content: center;
    }
  }

  &[data-is-opened='false'] {
    height: 42px;

    @media (--tablet) {
      height: 80px;
    }
  }
}

.item {
  scroll-snap-align: start;
  padding: 0 0 var(--spacing-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  text-decoration: none;
  transition: 0.1s;
  gap: 0;

  @media (--mobile) {
    min-width: calc(100vw - 70px);
    padding: 0;
    width: calc(100vw - 70px);
  }

  @media (--tablet) {
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0 0 100%;
  }
}

.content {
  @media (--mobile) {
    p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: var(--spacing-3);
      margin-top: 0;
    }
  }
  @media (--tablet) {
    width: 85%;

    p {
      margin-top: 0;
    }
  }
}

.toggle {
  background-color: var(--color-championship-white);
  border-radius: var(--spacing-1);
  cursor: pointer;
  padding: var(--spacing-1) var(--spacing-2);
  position: absolute;
  top: var(--spacing-1);
  right: var(--spacing-1);

  @media (--mobile) {
    & span {
      display: none;
    }
  }

  @media (--tablet) {
    top: var(--spacing-6);
    right: var(--spacing-5);

    & span {
      font-size: 12px;
    }

    & div {
      display: none;
    }
  }
}
