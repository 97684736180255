.wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.2s;
}

.wrapper[data-is-open='true'] {
  grid-template-rows: 1fr;
}

.inner {
  overflow: hidden;
}

.toast {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: min(840px, calc(100vw - var(--spacing-10)));
  min-width: 300px;
  padding: var(--spacing-4);
  font-family: var(--font-family-system);
  font-size: 14px;
  transition: all 0.3s ease;
  user-select: none;
  cursor: pointer;

  .icon {
    margin-right: var(--spacing-3);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .message {
    flex-grow: 1;
  }

  &[data-variant='primary'] {
    background-color: var(--color-mica-blue-50);
    /* border: 1px solid var(--color-mica-blue-500); */
    color: var(--color-mica-blue-700);

    .icon {
      background-color: var(--color-mica-blue-500);
      color: var(--color-championship-white);
    }

    &:hover {
      background-color: var(--color-mica-blue-100);
    }

    &:active {
      background-color: var(--color-mica-blue-200);
    }
  }

  &[data-variant='secondary'] {
    background-color: var(--color-heycar-mint-50);
    /* border: 1px solid var(--color-heycar-mint-500); */
    color: var(--color-heycar-mint-700);

    .icon {
      background-color: var(--color-heycar-mint-500);
      color: var(--color-championship-white);
    }

    &:hover {
      background-color: var(--color-heycar-mint-100);
    }

    &:active {
      background-color: var(--color-heycar-mint-200);
    }
  }

  &[data-variant='tertiary'] {
    background-color: var(--color-sunbeam-blue-50);
    /* border: 1px solid var(--color-sunbeam-blue-500); */
    color: var(--color-sunbeam-blue-700);

    .icon {
      background-color: var(--color-sunbeam-blue-500);
      color: var(--color-championship-white);
    }

    &:hover {
      background-color: var(--color-sunbeam-blue-100);
    }

    &:active {
      background-color: var(--color-sunbeam-blue-200);
    }
  }

  &[data-variant='success'] {
    background-color: var(--color-green-50);
    /* border: 1px solid var(--color-green-500); */
    color: var(--color-green-700);

    .icon {
      background-color: var(--color-green-500);
      color: var(--color-championship-white);
    }

    &:hover {
      background-color: var(--color-green-100);
    }

    &:active {
      background-color: var(--color-green-200);
    }
  }

  &[data-variant='warning'] {
    background-color: var(--color-mustang-yellow-50);
    /* border: 1px solid var(--color-mustang-yellow-500); */
    color: var(--color-mustang-yellow-700);

    .icon {
      background-color: var(--color-mustang-yellow-500);
      color: var(--color-championship-white);
    }

    &:hover {
      background-color: var(--color-mustang-yellow-100);
    }

    &:active {
      background-color: var(--color-mustang-yellow-200);
    }
  }

  &[data-variant='error'] {
    background-color: var(--color-old-ferrari-red-50);
    /* border: 1px solid var(--color-old-ferrari-red-500); */
    color: var(--color-old-ferrari-red-700);

    .icon {
      background-color: var(--color-old-ferrari-red-500);
      color: var(--color-championship-white);
    }

    &:hover {
      background-color: var(--color-old-ferrari-red-100);
    }

    &:active {
      background-color: var(--color-old-ferrari-red-200);
    }
  }

  &[data-variant='dark'] {
    background-color: var(--color-neutral-700);
    /* border: 1px solid var(--color-old-ferrari-red-500); */
    color: var(--color-championship-white);

    .icon {
      background-color: transparent;
      color: var(--color-championship-white);
    }

    &:hover {
      background-color: var(--color-neutral-700);
    }

    &:active {
      background-color: var(--color-neutral-700);
    }
  }

  &[data-disabled='true'] {
    background-color: var(--color-tarmac-grey-100);
    /* border: 1px solid var(--color-tarmac-grey-300); */
    color: var(--color-tarmac-grey-500);
    pointer-events: none;

    .icon {
      background-color: var(--color-tarmac-grey-300);
      color: var(--color-championship-white);
    }
  }

  @media (--mobile) {
    max-width: 100%;
  }
}
