.loadingBackgroundWrapper {
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
}

.loadingBackground {
  flex: 0 0 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: var(--color-neutral-50);
  background: linear-gradient(
    to right,
    var(--color-neutral-100) 8%,
    var(--color-neutral-300) 18%,
    var(--color-neutral-100) 33%
  );
  background-size: 800px 104px;

  opacity: 1;

  &[data-is-active='true'] {
    opacity: 1;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
