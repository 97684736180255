.wrapper {
  transition-behavior: allow-discrete;
  position: fixed;
  inset: 0;

  display: none;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.3);

  transition: 0.3s;
  backdrop-filter: blur(4px) brightness(90%) contrast(110%);
  overscroll-behavior: none;
  overflow: scroll;

  z-index: 9998;

  @starting-style {
    opacity: 0;
    backdrop-filter: blur(0);
  }

  body[data-is-ev-compare-modal-open='true'] & {
    display: grid;
  }

  & [class*='range-slider__thumb'] {
    transition: 0.1s, left 0s;
  }
}

.modal {
  overscroll-behavior: none;
  position: relative;
  background: white;
  cursor: default;

  box-shadow: 2px 8px 48px 0px #26262633;

  width: min(800px, 100vw);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 1fr;

  transition: 0.3s;

  @media (--mobile) {
    width: 100vw;
    height: 100vh;
  }

  @starting-style {
    transform: scale(0.95);
  }
}

.header {
  width: 100%;
  padding: 0 var(--spacing-10);

  border-bottom: #f3f3f3 solid 1px;

  font-weight: 600;
  font-size: 20px;

  display: grid;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  grid-template-columns: 1fr auto;

  @media (--mobile) {
    padding: 0 var(--spacing-5);
  }

  & svg {
    cursor: pointer;
  }
}

.content {
  place-self: stretch;
  padding: var(--spacing-8) var(--spacing-10);
  gap: var(--spacing-3);
  display: grid;
  grid-template-columns: 1fr 1fr 1px 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1px 1fr 1fr 58px;
  align-items: center;

  @media (--mobile) {
    padding: var(--spacing-8) var(--spacing-5);
    width: 100vw;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto 1px auto auto 1px auto auto;
    place-self: start;
  }
}

.description {
  grid-column: 1 / -1;
  grid-row: 1;
  margin-bottom: var(--spacing-3);
}

.currentVehicle {
  grid-column: 5;
  grid-row: 2 / 7;

  background: var(--color-secondary-50);
  place-self: stretch;
  padding: var(--spacing-3);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  line-height: 1.1;
  margin-bottom: auto;

  @media (--mobile) {
    grid-column: 2;
    grid-row: 8;
  }

  & .costValue {
    @starting-style {
      color: var(--color-secondary-700);
    }
  }
}

.currentVehicleTitle {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

.costLabel {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: calc(var(--spacing-2) * -1);
}

.costValue {
  font-weight: 600;
  font-size: 24px;
  width: fit-content;

  transition: all 0.4s ease-out, color 1s linear;
  color: var(--color-neutral-700);

  @starting-style {
    /* opacity: 0; */
    /* translate: -5px; */
    color: var(--color-primary-700);
    /* scale: 1.1; */
  }
}

.costValueSmall {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  width: fit-content;

  transition: 0.6s ease-out;
  transition-delay: 0.1s;

  @starting-style {
    opacity: 0;
    /* translate: -5px; */
    scale: 1.1;
    font-weight: 900;
  }
}

.iceVehicle {
  grid-column: 4;
  grid-row: 2 / 7;
  padding: var(--spacing-3);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  margin-bottom: auto;

  @media (--mobile) {
    grid-column: 1;
    grid-row: 8;
  }

  & .costValue {
    @starting-style {
      color: var(--color-primary-500);
    }
  }
}

.iceVehicleTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.horizontalBar {
  grid-column: 3;
  grid-row: 2 / 7;

  background: var(--color-neutral-300);
  place-self: stretch;

  @media (--mobile) {
    grid-column: 1/-1;
    grid-row: 4;
  }
}

.verticalBar {
  grid-row: 4;
  grid-column: 1 / 3;

  background: var(--color-neutral-300);
  place-self: stretch;

  @media (--mobile) {
    grid-column: 1/-1;
    grid-row: 7;
  }
}

.inputWrapper {
  align-self: center;
  margin: 0;
  width: 100%;
  > div {
    margin: 0;
    width: 100%;
  }
}

.input {
  text-align: right;
  width: 100%;

  > div {
    margin: 0;
    width: 100%;
  }
}

.slider {
  align-self: center;
  margin: 0;
  width: auto;

  & input {
    margin: 0;
    width: auto;
  }
}

.dropdown {
  grid-column: 1/3;
}

.disclaimer {
  grid-row: 7;
  grid-column: 1/-1;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle;

  color: var(--color-neutral-500);

  @media (--mobile) {
    grid-row: 9;
  }
}
