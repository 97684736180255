.title {
  margin: 0 0 var(--spacing-6);
}

.toggleWrapper {
  border: 1px solid var(--color-tarmac-grey-300);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-6);

  & button {
    border: 2px solid white;
    cursor: pointer;
    flex: 1 1 270px;
    font-weight: 500;
    height: 48px;
    text-align: center;
    transition: all 0.2s ease;

    &.selected {
      background: var(--color-mica-blue-50);
      border: 2px solid var(--color-mica-blue-500);
      color: var(--color-mica-blue-500);
    }
  }
}

.tab {
  background: #f1f4fa;

  & ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0px;
  }

  & li {
    display: flex;
    padding: 0 var(--spacing-3) var(--spacing-3);
    width: 100%;
    font-size: 14px;

    @media (--tablet) {
      flex: 0 0 50%;
    }
  }

  & span {
    margin-left: auto;
  }

  & svg {
    margin-right: 10px;
  }
}

.tabHeader {
  border-bottom: 1px dashed #b5b5b5;
  padding: var(--spacing-3) 0px;
  margin: 0 var(--spacing-3);

  & h5 {
    margin: var(--spacing-2) 0 0;
  }

  & p {
    margin: var(--spacing-2) 0 0;
  }
}
