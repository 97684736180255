.dividerSpacing {
  margin: 0 0 var(--spacing-10);

  @media (--tablet-l) {
    margin: var(--spacing-16) 0;
  }

  @media (--desktop-l) {
    margin: var(--spacing-20) 0;
  }
}
