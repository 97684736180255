:root {
  /* Font families */
  --font-family-system: Objektiv, sans-serif, 'Helvetica Neue', Helvetica, Arial;

  /* Font weights */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;
  --font-weight-xbold: 800;
}

/*
  Heading
*/
.typography-display {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-family: var(--font-family-system);
  font-weight: var(--font-weight-xbold);

  @media (--tablet) {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -2.5px;
  }
}

.typography-display-2 {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-family: var(--font-family-system);
  font-weight: var(--font-weight-bold);

  @media (--tablet) {
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -2.5px;
  }
}

.typography-heading-1 {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -2px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-heading-1_mobile {
  letter-spacing: -0.5px;
  font-size: 32px;
  line-height: 40px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-heading-2 {
  font-size: 32px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-heading-2_mobile {
  font-size: 28px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-heading-3 {
  font-size: 28px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-heading-3_mobile {
  font-size: 24px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-heading-4 {
  font-size: 24px;
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-heading-4_mobile {
  letter-spacing: 0;
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-heading-5 {
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-heading-5_mobile {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-heading-6 {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

/*
  Sub-Heading
*/
.typography-subheading-1 {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-subheading-2 {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

.typography-subheading-3 {
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

.typography-subheading-4 {
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}

/*
  Body
*/
.typography-body-1 {
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

.typography-body-2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

.typography-body-3 {
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}

.typography-body-4 {
  font-size: 14px;
  line-height: 22px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}

/*
  Caption
*/
.typography-caption-1 {
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

.typography-caption-2 {
  font-size: 12px;
  line-height: 18px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}

.typography-caption-3 {
  font-size: 10px;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-system);
}

.typography-caption-4 {
  font-size: 10px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

.typography-caption-5 {
  font-size: 10px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);
}

/*
  Overline
*/
.typography-overline-1 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

.typography-overline-2 {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-system);
}

/*
  Button
*/
.typography-button-1 {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}

.typography-button-2 {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}

.typography-button-3 {
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.2px;
  font-family: var(--font-family-system);
}
