.wrapper {
  background: var(--color-primary-50);
  overflow: hidden;
}
.content {
  gap: var(--spacing-6);
  padding: var(--spacing-10) 0;

  @media (--tablet-s) {
    padding: var(--spacing-12) 0;
  }
  @media (--tablet-s) and (orientation: landscape) {
    align-self: center;
  }
  @media (--tablet-l) {
    gap: var(--spacing-8);
    padding: var(--spacing-16) 0;
  }

  @media (--desktop) {
    padding-top: calc(var(--spacing-10) * 2);
    padding-bottom: var(--spacing-10);
  }

  & a {
    text-decoration: none;
    color: var(--color-mica-blue-500);
  }
}

.heading {
  margin: 0;
}
.subHeading {
  margin-bottom: var(--spacing-6);
}
.tcs {
  display: inline;
  padding-top: var(--spacing-3);
  @media (--tablet-s) {
    padding-top: var(--spacing-6);
  }
}

.imageContainer {
  flex: 2.5 1 auto;
  @media (--tablet-s) {
    margin-right: -81px;
  }
  @media (--tablet-l) {
    margin-right: 0;
  }
  & img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
}
