.wrapper {
  --pdpOverviewPadding: var(--spacing-8);

  interpolate-size: allow-keywords; /* 👈 */
  background-color: var(--color-secondary-700);
  color: white;
  padding: var(--pdpOverviewPadding);
  position: relative;
  overflow: clip;

  display: grid;
  grid-template-columns: auto auto auto;
  grid-auto-flow: row;
  gap: var(--spacing-5);

  margin-bottom: var(--spacing-5);
  transition: 0.4s ease-out;

  @starting-style {
    opacity: 0;
  }

  @media (--desktop) {
    padding-right: 132px;
  }

  @media (--mobile) {
    grid-template-columns: 1fr;
  }

  &:has([data-no-ev-data]) {
    display: none;
  }
}

.title {
  grid-column: 1 / -1;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  margin-bottom: var(--spacing-2);
  line-height: 1.3;
}

.spec {
  display: grid;
  gap: 2px;
  grid-template-columns: 32px 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column: span 1;
  align-items: center;
  transition: 0.4s ease-out;
  position: relative;

  @starting-style {
    opacity: 0;
  }

  @media (--mobile) {
    &[data-should-hide-mobile='true'] {
      display: none;
    }

    .wrapper:has(.showToggle input:checked) & {
      display: grid;
    }
  }
}

.specIcon {
  width: 24px;
  height: 24px;
  grid-column: 1;
  grid-row: 1;
}

.specLabel {
  font-size: 14px;
  grid-column: 2;
  grid-row: 1;
  line-height: 1.4;
}

.specValue {
  grid-column: 2;
  grid-row: 2;
  font-size: 16px;
  font-weight: 500;
}

.compareButtonWrapper {
  grid-column: 1 / -1;
}

.compareButton {
  color: white;
  border-color: white;

  &:after {
    border-color: white;
  }

  @media (--mobile) {
    width: 100%;

    & button {
      width: 100%;
    }
  }
}

.image {
  position: absolute;

  height: 35%;
  width: auto;
  aspect-ratio: 16/9;
  object-fit: contain;
  object-position: right bottom;
  bottom: 20px;
  left: max(380px, 45%);

  transition: 1s ease-out;

  & * {
    fill: white;
  }

  @starting-style {
    opacity: 0;
    transform: translateX(10px) translateY(2px);
  }

  @media (--mobile) {
    display: none;
  }
}

.footnotes {
  grid-column: 1 / -1;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
}

.showToggle {
  grid-column: 1 / -1;
  place-self: center;
  display: none;

  @media (--mobile) {
    display: block;
  }

  & input {
    display: none;
  }

  & label {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
    cursor: pointer;
    appearance: none;
  }

  & .showLess {
    display: none;
  }

  &:has(input:checked) {
    & .showMore {
      display: none;
    }

    & .showLess {
      display: block;
    }
  }
}

.chargerType {
  & svg {
    position: absolute;
    height: 48px;
    width: 48px;
    top: 0;
    left: min(160px, calc(100vw - 48px));
    border: 1px solid white;
    border-radius: 5px;
  }
}
