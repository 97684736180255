.financeOptionsType {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--color-tarmac-grey-300);
  margin: 0;
  padding: 0;
  height: 48px;
  position: relative;
}

.financeOption {
  flex: 1;
  text-align: center;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background: white;
  transition: all 0.2s ease;
  border-right: 1px solid var(--color-tarmac-grey-300);
  margin: 0;

  @media (--mobile) {
    height: 48px;
    border-right: none;
    border-bottom: 1px solid var(--color-tarmac-grey-300);

    &:last-child {
      border-bottom: none;
    }
  }

  &:last-child {
    border-right: none;
  }

  & > * {
    cursor: pointer;
  }

  & > input {
    opacity: 0;
    height: 0;
    width: 0;
  }

  & > label {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 var(--spacing-2);
  }

  &:has(input[type='radio']:checked) {
    background: var(--color-mica-blue-50);
    border: 2px solid var(--color-mica-blue-500);
    color: var(--color-mica-blue-500);
    margin: -1px;
    z-index: 1;
    height: calc(100% + 2px);

    @media (--mobile) {
      height: calc(48px + 2px);
    }
  }
}

.divider {
  border-bottom: 1px solid var(--neutral-100, #e6e6e6);
  margin: var(--spacing-6) 0 var(--spacing-6) !important;
}

@media (--mobile) {
  .financeOptionsType {
    flex-direction: column;
    height: auto;
  }

  .financeOptionsType:has(.financeOption:nth-child(2):last-child) {
    min-height: 96px;
  }

  .financeOptionsType:has(.financeOption:nth-child(2):last-child) .financeOption {
    height: 48px;
    min-height: 48px;
    flex: 0 0 48px;
  }

  .financeOptionsType:has(.financeOption:nth-child(3)) {
    min-height: 144px;
  }

  .financeOptionsType:has(.financeOption:nth-child(3)) .financeOption {
    height: 48px;
    min-height: 48px;
    flex: 1;
  }
}
