@import './colors.css';
@import './spacing.css';
@import './shadows.css';
@import './typography.css';
@import './breakpoints.css';

/* Sizes */
:root {
  --button-small-height: var(--size-small-height);
  --button-large-height: var(--size-large-height);
}

/* Variants */
:root {
  /* disabled */
  --button-disabled-color: var(--color-neutral-300);
  --button-disabled-bg-color: var(--color-neutral-50);

  /* primary */
  --button-primary-color: #fff;
  --button-primary-base-bg-color: var(--color-primary-500);
  --button-primary-hover-bg-color: var(--color-primary-600);
  --button-primary-active-bg-color: var(--color-primary-700);

  /* tertiary */
  --button-tertiary-color: var(--color-neutral-700);
  --button-tertiary-base-bg-color: var(--color-tertiary-500);
  --button-tertiary-hover-bg-color: var(--color-tertiary-600);
  --button-tertiary-active-bg-color: var(--color-tertiary-700);

  /* whatsapp */
  --button-whatsapp-color: #fff;
  --button-whatsapp-base-bg-color: var(--color-whatsapp-500);
  --button-whatsapp-hover-bg-color: var(--color-whatsapp-600);
  --button-whatsapp-active-bg-color: var(--color-whatsapp-700);
}

/* Chip */
:root {
  /* border */
  --chip-border-color: var(--color-neutral-300);

  /* default */
  --chip-default-background: var(--color-neutral-50);

  /* hover */
  --chip-hover-background: var(--color-neutral-100);

  /* pressed */
  --chip-pressed-background: var(--color-neutral-200);

  /* disable */
  --chip-disable-background: var(--color-neutral-50);

  /* text */
  --chip-text-color: var(--color-tarmac-grey-700);

  /* text disabled */
  --chip-text-disabled-color: var(--color-tarmac-grey-400);

  /* selected color */
  --chip-selected-color: var(--color-mica-blue-500);

  /* border */
  --chip-selected-border-color: var(--color-mica-blue-500);
}

/* Dropdown */
:root {
  --option-background-color: var(--color-tarmac-grey-50);
  --option-text-color: var(--color-tarmac-grey-700);
  --container-border-color: var(--color-neutral-500);
  --container-focus-border-color: var(--color-mica-blue-500);
  --container-disabled-color: var(--color-neutral-300);
  --options-border-color: var(--color-neutral-700);
  --options-background-color: var(--color-championship-white);
}

/* FormControl */
:root {
  /* Color - base */
  --form-control-base-border-color: var(--color-neutral-500);
  --form-control-hover-border-color: var(--color-neutral-600);
  --form-control-focused-border-color: var(--color-primary-500);
  --form-control-filled-border-color: var(--color-neutral-500);
  --form-control-label-color: var(--color-neutral-700);
  --form-control-hint-color: var(--color-neutral-700);
  --form-control-icon-color: var(--color-neutral-700);

  /* Color - error */
  --form-control-error-color: var(--color-error-700);
  --form-control-error-label-color: var(--color-error-700);

  /* Color - disabled */
  --form-control-disabled-bg-color: var(--color-neutral-300);
  --form-control-disabled-color: var(--color-neutral-300);

  /* Sizes */
  --form-control-min-height: 48px;
  --from-control-legend-height: 11px;
  --form-control-input-padding: 0;
  --form-control-hint-fint-size: 12px;
}

/* Col */
:root {
  --grid-col-width: calc(100% / 12);
  margin: 0 auto;
}

/* SvgIcon */
:root {
  /* primary */
  --icon-primary-color: var(--color-primary-500);

  /* secondary */
  --icon-secondary-color: var(--color-secondary-500);

  /* tertiary */
  --icon-tertiary-color: var(--color-tertiary-500);
}

/* Icons */
:root {
  /* primary */
  --icon-primary-color: var(--color-primary-500);

  /* secondary */
  --icon-secondary-color: var(--color-secondary-500);

  /* tertiary */
  --icon-tertiary-color: var(--color-tertiary-500);
}

/* Input */
:root {
  /* Color */
  --input-color: var(--color-neutral-900);
  --input-caret-color: var(--color-neutral-900);
  --input-placeholder-color: var(--color-neutral-900);
  --input-disabled-color: var(--color-neutral-300);
}

/* Logo */
:root {
  /* primary */
  --fill-primary-color: var(--color-championship-white);
  --fill-primary-undermark-color: var(--color-heycar-mint-500);

  /* secondary */
  --fill-secondary-color: var(--color-mica-blue);
  --fill-secondary-undermark-color: var(--color-heycar-mint-500);

  /* tertiary */
  --fill-tertiary-color: var(--color-mica-blue);
  --fill-tertiary-undermark-color: var(--color-championship-white);
}

/* Header */

:root {
  /* background color */
  --header-nav-background-color: var(--color-primary-700);
  /* font color */
  --header-nav-item-color: var(--color-championship-white);
  --header-nav-item-hover-bg: var(--color-primary-800);
  --header-nav-item-active-bg: var(--color-primary-900);
  --header-nav-counter-bg: var(--color-secondary-500);
  --header-nav-fav-icon-bg: var(--color-secondary-100);
}

/* Pill */
:root {
  /* background color */
  --pill-background-color: var(--color-tarmac-grey-50);

  /* text color */
  --pill-text-color: var(--color-tarmac-grey-700);
}

/* Slider */
:root {
  --slider-thumb-size: 22px;
  --slider-halo-size: 40px;
  --slider-focus-visible: calc(var(--slider-thumb-size) * 1.1818181818181819);
  --slider-thumb-left-offset: -2px;
}

/* SliderWithHistogram */
:root {
  --histogram-height: 64px;
  --slider-height: 22px;
}

/* Switch */
:root {
  --switch-checked-color: var(--color-neutral-700);
  --switch-unchecked-color: var(--color-neutral-500);
  --switch-unchecked-hover-color: var(--color-neutral-700);
  --switch-unchecked-pressed-color: var(--color-neutral-700);
  --switch-disabled-color: var(--color-neutral-300);
  --switch-thumb-color: var(--color-championship-white);
  --switch-thumb-disabled-color: var(--color-neutral-200);
}

/* Textarea */
:root {
  /* Color */
  --textarea-color: var(--color-neutral-700);
  --textarea-caret-color: var(--color-neutral-700);
  --textarea-placeholder-color: var(--color-neutral-700);
  --textarea-disabled-color: var(--color-neutral-300);

  /* Sizes */
  --form-textarea-min-height: 80px;
}
