.wrapper {
  user-select: none;
  position: relative;
}

.fullWidth {
  --internalGap: var(--spacing-5);
  --maxPaddingWidth: min(1340px, 100vw - (2 * var(--internalGap)));
  --sidePadding: calc((100vw - var(--maxPaddingWidth)) / 2);

  @media (--tablet) {
    --internalGap: var(--spacing-6);
  }

  @media (--tablet-l) {
    --internalGap: var(--spacing-8);
  }

  @media (--desktop-m) {
    --internalGap: var(--spacing-8);
    --maxPaddingWidth: min(1373px, 100vw - (2 * var(--internalGap)));
  }

  @media (--desktop-l) {
    --internalGap: var(--spacing-16);
    --maxPaddingWidth: min(1312px, 100vw - (2 * var(--internalGap)));
  }

  position: relative;
  left: calc(var(--sidePadding) * -1);
  right: calc(var(--sidePadding) * -1);
  width: 100vw;
}

.nav {
  position: absolute;
  right: 0;
  top: -70px;

  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: var(--spacing-6);

  @media (--tablet-l) {
    display: flex;
  }

  & svg {
    height: var(--spacing-9);
    width: var(--spacing-9);
    padding: 0 10px;
    cursor: pointer;

    opacity: 0.3;

    &[data-is-active='true'] {
      opacity: 1;
    }
  }
}

.scrollBox {
  width: 100vw;
  scroll-snap-type: x proximity;
  overflow-x: scroll;
  position: relative;
  /* left: calc(-1 * var(--sidePadding)); */
  padding: 0 var(--sidePadding);
  scroll-padding-left: var(--sidePadding);
  scroll-padding-right: var(--sidePadding);
  display: flex;
  flex-direction: row;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

[data-tenant='rac'] {
  .nav {
    & svg,
    & path {
      fill: var(--color-primary-500);
    }

    & svg {
      height: var(--spacing-10);
      width: var(--spacing-10);
    }
  }
}

.dots {
  display: flex;
  gap: var(--spacing-1);
}

.dot {
  background-color: var(--color-primary-200);
  height: var(--spacing-2);
  width: var(--spacing-2);
  border-radius: 50%;

  &[data-is-active='true'] {
    background-color: var(--color-primary-500);
  }
}
