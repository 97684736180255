@import '../Vars/index.css';

/* Default theme (light) */

:root {
  /*
    Colors
  */
  --color-primary-50: var(--color-mica-blue-50);
  --color-primary-100: var(--color-mica-blue-100);
  --color-primary-200: var(--color-mica-blue-200);
  --color-primary-300: var(--color-mica-blue-300);
  --color-primary-400: var(--color-mica-blue-400);
  --color-primary-500: var(--color-mica-blue-500);
  --color-primary-600: var(--color-mica-blue-600);
  --color-primary-700: var(--color-mica-blue-700);
  --color-primary-800: var(--color-mica-blue-800);
  --color-primary-900: var(--color-mica-blue-900);
  --color-secondary-50: var(--color-heycar-mint-50);
  --color-secondary-100: var(--color-heycar-mint-100);
  --color-secondary-200: var(--color-heycar-mint-200);
  --color-secondary-300: var(--color-heycar-mint-300);
  --color-secondary-400: var(--color-heycar-mint-400);
  --color-secondary-500: var(--color-heycar-mint-500);
  --color-secondary-600: var(--color-heycar-mint-600);
  --color-secondary-700: var(--color-heycar-mint-700);
  --color-secondary-800: var(--color-heycar-mint-800);
  --color-secondary-900: var(--color-heycar-mint-900);
  --color-tertiary-50: var(--color-sunbeam-blue-50);
  --color-tertiary-100: var(--color-sunbeam-blue-100);
  --color-tertiary-200: var(--color-sunbeam-blue-200);
  --color-tertiary-300: var(--color-sunbeam-blue-300);
  --color-tertiary-400: var(--color-sunbeam-blue-400);
  --color-tertiary-500: var(--color-sunbeam-blue-500);
  --color-tertiary-600: var(--color-sunbeam-blue-600);
  --color-tertiary-700: var(--color-sunbeam-blue-700);
  --color-tertiary-800: var(--color-sunbeam-blue-800);
  --color-tertiary-900: var(--color-sunbeam-blue-900);
  --color-warning-50: var(--color-mustang-yellow-50);
  --color-warning-100: var(--color-mustang-yellow-100);
  --color-warning-200: var(--color-mustang-yellow-200);
  --color-warning-300: var(--color-mustang-yellow-300);
  --color-warning-400: var(--color-mustang-yellow-400);
  --color-warning-500: var(--color-mustang-yellow-500);
  --color-warning-600: var(--color-mustang-yellow-600);
  --color-warning-700: var(--color-mustang-yellow-700);
  --color-warning-800: var(--color-mustang-yellow-800);
  --color-warning-900: var(--color-mustang-yellow-900);
  --color-highlight-50: var(--color-old-ferrari-red-50);
  --color-highlight-100: var(--color-old-ferrari-red-100);
  --color-highlight-200: var(--color-old-ferrari-red-200);
  --color-highlight-300: var(--color-old-ferrari-red-300);
  --color-highlight-400: var(--color-old-ferrari-red-400);
  --color-highlight-500: var(--color-old-ferrari-red-500);
  --color-highlight-600: var(--color-old-ferrari-red-600);
  --color-highlight-700: var(--color-old-ferrari-red-700);
  --color-highlight-800: var(--color-old-ferrari-red-800);
  --color-highlight-900: var(--color-old-ferrari-red-900);
  --color-success-50: var(--color-green-50);
  --color-success-100: var(--color-green-100);
  --color-success-200: var(--color-green-200);
  --color-success-300: var(--color-green-300);
  --color-success-400: var(--color-green-400);
  --color-success-500: var(--color-green-500);
  --color-success-600: var(--color-green-600);
  --color-success-700: var(--color-green-700);
  --color-success-800: var(--color-green-800);
  --color-success-900: var(--color-green-900);
  --color-error-50: var(--color-red-50);
  --color-error-100: var(--color-red-100);
  --color-error-200: var(--color-red-200);
  --color-error-300: var(--color-red-300);
  --color-error-400: var(--color-red-400);
  --color-error-500: var(--color-red-500);
  --color-error-600: var(--color-red-600);
  --color-error-700: var(--color-red-700);
  --color-error-800: var(--color-red-800);
  --color-error-900: var(--color-red-900);
  --color-neutral-50: var(--color-tarmac-grey-50);
  --color-neutral-100: var(--color-tarmac-grey-100);
  --color-neutral-200: var(--color-tarmac-grey-200);
  --color-neutral-300: var(--color-tarmac-grey-300);
  --color-neutral-400: var(--color-tarmac-grey-400);
  --color-neutral-500: var(--color-tarmac-grey-500);
  --color-neutral-600: var(--color-tarmac-grey-600);
  --color-neutral-700: var(--color-tarmac-grey-700);
  --color-neutral-800: var(--color-tarmac-grey-800);
  --color-neutral-900: var(--color-tarmac-grey-900);

  /*
    Sizes
  */
  --size-small-height: var(--spacing-8);
  --size-middle-height: var(--spacing-10);
  --size-large-height: var(--spacing-12);

  /*
    Border radius
  */

  --border-radius: 0;
}

html {
  font-size: 16px;
}

body {
  color: var(--color-neutral-700);
}
