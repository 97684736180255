.carFinanceWidgetMobile {
  margin-bottom: var(--spacing-10);
}

.pdpMobileDmpu {
  display: block;
  margin-top: var(--spacing-6);
  background-repeat: repeat;
  background-size: 35%;

  && [class^='Ad_wrapper'] {
    margin-top: 0;
  }

  @media (--tablet-l) {
    background: none;
    display: none;
  }
}
