.wrapper {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-6);
  margin-bottom: var(--spacing-6);
  @media (--tablet) {
    margin-bottom: 0;
  }
}

.infomationContainer:not(:last-child) {
  border-right: 1px solid #ccc;
  padding-right: var(--spacing-6);
}

.header {
  margin: 0 0 var(--spacing-2) !important;
}
