.layout {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--spacing-6) var(--spacing-16);
  flex-wrap: wrap;

  @media (--desktop-l) {
    gap: var(--spacing-5) 80px;
  }
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-5);

  @media (--tablet-l) {
    gap: var(--spacing-6);
    width: 448px;
  }

  @media (--desktop-l) {
    width: 477px;
  }

  && {
    & > * {
      margin: 0;

      & p {
        margin: 0;
        padding: 0;
        line-height: 1.5;
      }
    }
  }
}

.title {
  line-height: 1.3;

  @media (--tablet-l) {
    max-width: 350px;
  }

  @media (--mobile) {
    font-size: var(--spacing-6);
  }
}

.cta {
  width: fit-content;
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5);

  & > p > strong {
    font-weight: 500;
  }

  @media (--tablet-l) {
    max-width: 455px;
  }
}

.video {
  flex: 1 1 calc(755px * 0.6);

  aspect-ratio: 16/9;
  background-color: #f5f5f5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--tablet-l) {
    flex: 1 1 auto;
    width: 448px;
    height: 280px;
    align-self: center;
  }

  @media (--desktop-l) {
    width: 755px;
    height: unset;
  }
}

.playIcon {
  position: absolute;
  z-index: 11;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1000px;
  height: 150px;
  width: 150px;
  padding: var(--spacing-8);
  padding-right: var(--spacing-5);

  cursor: pointer;
  pointer-events: none;
  transition: 0.2s ease-in-out;
  transform: scale(1);

  &[data-has-started='true'] {
    transition: 0.6s ease-in-out;
    transform: scale(1.3);
    opacity: 0;
    pointer-events: none;
  }

  @media (--mobile) {
    height: 80px;
    width: 80px;
    padding: var(--spacing-6);
    padding-right: var(--spacing-4);
  }
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
  opacity: 1;
  z-index: 10;
  transition: opacity 1.4s ease-in;
  cursor: pointer;

  &[data-has-started='true'] {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    + .playIcon {
      /* transition: 0.1s linear; */
      transform: scale(1.05);
    }
  }
}
