.pdpTopAds {
  position: sticky;
  top: var(--pdpTopAdsOffset, 64px);
  left: 0;
  width: 100vw;
  margin: 0 -20px;
  z-index: 99;

  @media (--tablet) {
    top: 64px;
  }

  &[data-has-default-header='true'] {
    @media (--tablet) {
      top: 72px;
    }
  }

  [data-service-consumer='native'] & {
    background: white;
    opacity: 0;

    transition: 0.4s;
    transition-delay: 0.01s;
    overflow: hidden;

    &:has(iframe) {
      opacity: 1;
      height: 66px;
    }
  }
  [data-has-footer-sticky='true'] & {
    top: var(--pdpTopAdsOffset, 0);
    @media (--tablet-l) {
      top: 72px;
    }
  }
}

.pdpMobileBannerAd {
  display: block;
  height: 66px;
  position: relative;

  @media (min-width: 776px) {
    display: none;
  }

  > div {
    left: 0;
    right: 0;
    background: white;
    z-index: 50;
    padding: var(--spacing-2) 0;
  }

  > div[data-is-viewable='false'] {
    padding: 0;
  }

  &:has(> div[data-is-viewable='false']) {
    height: 0;
  }
}

.pdpLeaderboardAd {
  display: none;
  height: 114px;

  @media (min-width: 776px) {
    display: block;
  }

  @media (--tablet-l) {
    display: none;
  }

  > div {
    left: 0;
    right: 0;
    background: white;
    z-index: 50;
    padding: var(--spacing-3) 0;
  }
}

.pdpBillboardAd {
  width: 100%;
  display: none;

  @media (--tablet-l) {
    display: block;
    width: unset;
  }

  @media (--desktop) {
    display: block;
    width: unset;
    margin-right: -100px;
    max-width: calc(100vw - 40px - 40px - 300px - 40px);
  }
}

.pdpDmpuAdContainer {
  order: 1;
  padding-bottom: 200px;

  @media (--tablet-l) {
    display: block;
    order: unset;
  }

  @media (--desktop) {
    display: block;
  }
}

.pdpDmpuAd {
  @media (--mobile) {
    && [data-with-margin='true'] {
      margin-bottom: 0;
    }

    background-repeat: repeat;
    background-size: 35%;
    margin-top: var(--spacing-10);

    && [class^='Ad_wrapper'] {
      margin-top: 0;
    }
  }

  @media (--tablet-l) {
    background: none !important;

    && [data-unit-id] {
      top: calc(var(--sticky-info-height, 0) + 145px);
    }
  }
}

.pdpMobileDmpu {
  display: block;
  margin-top: var(--spacing-6);
  background-repeat: repeat;
  background-size: 35%;

  && [class^='Ad_wrapper'] {
    margin-top: 0;
  }

  @media (--tablet-l) {
    background: none;
    display: none;
  }
}

.pdpDmpuStickyAd {
  display: none;

  @media (--tablet-l) {
    display: block;
    position: sticky;
    top: calc(var(--sticky-info-height, 412px) + 145px);
  }
}

.carFinanceWidget {
  display: none;

  @media (--tablet-l) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--spacing-10);
    && a {
      top: calc(var(--sticky-info-height, 412px) + 145px);
    }
  }
}
