.wrapper {
  --internalGap: var(--spacing-5);
  --maxPaddingWidth: min(1340px, 100vw - (2 * var(--internalGap)));
  --sidePadding: calc((100vw - var(--maxPaddingWidth)) / 2);
  --currentContainerWidth: calc(100vw - (2 * var(--sidePadding)));

  @media (--tablet) {
    --internalGap: var(--spacing-6);
  }

  @media (--tablet-l) {
    --internalGap: var(--spacing-8);
  }

  @media (--desktop-m) {
    --internalGap: var(--spacing-8);
    --maxPaddingWidth: min(1373px, 100vw - (2 * var(--internalGap)));
  }

  @media (--desktop-l) {
    --internalGap: var(--spacing-16);
    --maxPaddingWidth: min(1312px, 100vw - (2 * var(--internalGap)));
  }
}

.wrapper {
  max-width: 100%;

  &[data-with-top-margin='true'] {
    padding-top: var(--spacing-10);
  }

  &[data-with-bottom-margin='true'] {
    padding-bottom: var(--spacing-10);
  }

  &[data-with-top-border='true'] {
    border-top: 1px solid var(--color-tarmac-grey-300);
    padding-top: var(--spacing-20);
    margin-top: var(--spacing-10);

    @media (--mobile) {
      padding-top: var(--spacing-10);
    }
  }

  &[data-with-bottom-border='true'] {
    border-bottom: 1px solid var(--color-tarmac-grey-300);
    padding-bottom: var(--spacing-20);
    margin-bottom: var(--spacing-10);

    @media (--mobile) {
      padding-bottom: var(--spacing-10);
    }
  }

  display: none;
  transition: 0.4s;
  opacity: 0;

  &:has([data-vehicle-tile]),
  &:has([data-vehicle-tile-skeleton]) {
    display: flex;
    flex-direction: column;
    opacity: 1;
  }

  @starting-style {
    opacity: 0;
  }
}

.title.title {
  font-size: clamp(24px, 4vw, 32px);
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.lastSearch.lastSearch {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration: underline;
  padding: 0;
  margin: 0;
  margin-top: var(--spacing-3);

  & > * {
    color: inherit;
  }
}

.list {
  gap: var(--spacing-4);
  width: max-content;
  margin-top: var(--spacing-5);

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.trackElement {
  display: contents;
}

.item {
  --maxItems: 1;
  text-decoration: none;
  display: flex;

  width: max(
    calc(
      (var(--currentContainerWidth) - ((var(--maxItems) - 1) * var(--spacing-4))) / var(--maxItems)
    ),
    300px
  );

  @media (min-width: 564px) {
    --maxItems: 2;
  }

  @media (--tablet) {
    --maxItems: 3;
  }

  @media (--tablet-l) {
    --maxItems: 4;
  }

  @media (--desktop) {
    --maxItems: 4;
  }

  &&& > * {
    max-width: none;
  }

  && article {
    max-width: none;
    width: 100%;
  }
}

.continueTile {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-neutral-100, #e6e6e6);
  gap: var(--spacing-2);

  color: var(--Text-Default, #303030);
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  min-height: 426px;
  text-decoration: none;
  overflow: clip;

  & * {
    user-select: none;
    text-decoration: none;
  }

  &:hover {
    & .continueTileIcon {
      transform: translateX(-2px);
    }
  }

  &:active,
  &:focus {
    & .continueTileIcon {
      transform: translateX(-5px);
    }
  }
}

.continueTileIcon {
  height: 100px;
  width: 100px;
  align-content: center;
  transition: 0.4s;
}
