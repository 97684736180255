.wrapper {
  margin: var(--spacing-10) 0;

  & h3 {
    font-size: 24px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  flex: unset;
  gap: var(--spacing-8);
  margin: 0;
  padding: 0;

  & .item {
    display: flex;
    gap: var(--spacing-3);
    padding: var(--spacing-1) 0;
    align-items: center;
    position: relative;

    & h4 {
      font-size: 16px;
      line-height: 26px;
      margin: 0;
    }

    & p {
      font-size: 14px;
      line-height: 22px;
      margin: 0;
    }
  }

  & .icon {
    background-color: var(--color-primary-50);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
  }

  & .iconWrapper {
    position: relative;
    height: 100%;
    width: 24px;
  }

  & .line {
    position: relative;

    &:after {
      background-color: var(--color-primary-50);
      content: '';
      left: calc(50% - 1.5px);
      bottom: -100%;
      text-align: center;
      width: 3px;
      position: absolute;
      height: 100%;
      z-index: 0;
    }
  }

  & .content {
    flex: 1;
  }
}
