& .container {
  h2 {
    margin-bottom: 0;
  }
}

.cta {
  & a {
    color: var(--color-neutral-700);
  }
}

.vehicle {
  width: 323px;

  @media (--tablet) {
    flex-grow: 1;

    & > a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.vehicles {
  display: grid;
  padding: 0;
  margin: 0;
  margin-top: var(--spacing-8);
  list-style: none;
  grid-auto-flow: column;
  justify-content: flex-start;
  gap: var(--spacing-2);

  @media (--tablet) {
    gap: var(--spacing-3);
  }

  @media (--tablet-l) {
    gap: var(--spacing-4);
  }

  @media (--desktop) {
    gap: var(--spacing-5);
  }

  & a {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
  }
}

.leasingVehicles {
  padding-bottom: var(--spacing-8);
  @media (--desktop) {
    padding-bottom: 0;
  }
}
