.additionalServicesWrapper {
  margin-top: var(--spacing-3);
  & > p {
    display: flex;
    align-items: center;
    margin: 0;
  }

  & > svg {
    cursor: pointer;
    margin-left: auto;
  }
}
.additionalServiceCheckbox {
  & label[for='additionalServices'] {
    display: flex;
    align-items: flex-start;
  }
}
.additionalServicesDe {
  margin-top: -46px;
}

.infoIconWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-3);

  & > span:first-child {
    font-size: 14px;
    margin-bottom: var(--spacing-3);
  }
  & > span > svg:first-child {
    margin-bottom: var(--spacing-3);
  }
}
.serviceContainer {
  display: flex;
  padding: var(--spacing-1) 0;

  & > div:last-child {
    display: flex;
  }

  & > span {
    display: flex;
    align-items: center;
  }
}

.servicePriceContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.servicePrice {
  margin: 0;
  margin-right: 8px;
}

.monthlyRate {
  margin-right: var(--spacing-1);
  margin-bottom: var(--spacing-3);
}

.videoIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  margin-bottom: var(--spacing-2);
  cursor: pointer;
}

.infoIcon {
  margin-left: var(--spacing-1);
  margin-bottom: var(--spacing-2);
  cursor: pointer;
}
.noInfo {
  cursor: default !important;
}

.infoIconNoVideo {
  margin-left: var(--spacing-6);
  cursor: pointer;
}

.pdflink {
  color: black;
  cursor: pointer;
}

.modalContainer {
  @media (--tablet-l) {
    height: 635px;
    width: 800px;
  }
}

.modalBody {
  overflow: auto;
  & a {
    color: inherit;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
}
