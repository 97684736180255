.wrapper {
  margin: 0 auto;
  max-width: 1040px;

  @media (--tablet) {
    display: grid;
    grid-template-columns: [left] 1fr [right] 1fr;
    column-gap: var(--spacing-6);

    & h2 {
      width: 100%;
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }
}

.prosConsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-5) 0;
}

.half {
  &:first-of-type {
    border-bottom: 1px solid var(--neutral-300-disabled, #b5b5b5);
    padding-bottom: var(--spacing-5);
  }

  & ul {
    margin: 0;
    padding-left: var(--spacing-5);
  }

  & li {
    margin-bottom: var(--spacing-3);
    font-size: 14px;
  }

  &[data-has-icon='true'] {
    & ul {
      margin: 0;
      list-style: none;
      padding-left: var(--spacing-8);
    }

    & li {
      position: relative;
      margin-bottom: var(--spacing-3);

      &:before {
        content: '';
        position: absolute;
        height: 16px;
        width: 16px;
        background-image: url('./images/red-minus-3x.png');
        background-size: cover;
        left: -30px;
        top: 2px;
      }
    }
  }

  &.pros {
    & li:before {
      background-image: url('./images/green-plus-3x.png');
    }
  }

  &.cons {
    & li:before {
      background-image: url('./images/red-minus-3x.png');
    }
  }

  & h3,
  & h6 {
    margin: 0;
    padding: 0;
  }

  @media (--tablet) {
    &:first-of-type {
      border-right: 1px solid var(--neutral-300-disabled, #b5b5b5);
      padding-right: var(--spacing-6);
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
