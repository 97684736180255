.toastList {
  position: fixed;
  bottom: var(--spacing-10);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  display: flex;
  flex-direction: column-reverse;
  gap: var(--spacing-3);

  width: 100vw;
  align-items: center;

  & > div {
    &[data-is-inactive='true'] {
      grid-template-rows: 0fr;
      margin-top: calc(var(--spacing-3) * -1);
    }
  }

  [lang='fr-FR'] & {
    bottom: 100px;

    @media (--desktop) {
      bottom: 120px;
    }
  }
}
