.root {
  @media (--tablet-l) {
    display: grid;
    grid-template-columns: [left] 1fr [right] 1fr;
    column-gap: var(--spacing-4);
  }
  @media (--desktop) {
    display: grid;
    grid-template-columns: [left] 0.706fr [right] 1fr;
    column-gap: var(--spacing-4);
  }
}

.title {
  && {
    @media (--tablet) {
      grid-column: left;
      margin-bottom: var(--spacing-7);
    }
  }
}

.description {
  grid-column: right;
  margin-bottom: var(--spacing-6);

  @media (--tablet-l) {
    margin-top: 0;
  }
}

.accordions {
  grid-column: right;
}

.accordionTitle {
  margin: 0;
}

.button {
  justify-self: flex-start;
  grid-column: right;
  margin-top: var(--spacing-6);
}
