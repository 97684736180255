.wrapper {
  border: 1px solid var(--neutral-300-disabled, #b5b5b5);
  margin: 0 0 var(--spacing-10) 0;
}

.wrapperInner {
  padding: var(--spacing-10) var(--spacing-5);
  border-top: 10px solid var(--primary-50-surface, #f1f4fa);

  @media (--tablet) {
    padding: var(--spacing-10) var(--spacing-6);
  }

  @media (--tablet-l) {
    padding: var(--spacing-10) var(--spacing-8);
  }

  @media (--desktop-l) {
    padding: var(--spacing-10);
  }
}

.termsAndConditions {
  padding: var(--spacing-3);
  color: var(--neutral-700);
  & p,
  & div {
    margin: 0;

    @media (--tablet) {
      margin-top: 12px;
    }
    &:has(> br) {
      display: none;
    }
  }
}

.infoIcon {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 var(--spacing-1) 0 var(--spacing-1);
}
